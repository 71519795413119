<script lang="ts">
  import Cta from "$lib/ui/system/Cta.svelte";
  import LogoBoxes3DLazy from "./LogoBoxes3DLazy.svelte";

  export let with3d = false;
  export let isInternal = false;
</script>

<div
  class="h-full p-8 bg-white/5 border border-white/10 rounded-xl flex gap-10"
>
  {#if with3d}
    <div
      class="hidden sm:block w-48 h-36 relative overflow-hidden shrink-0 my-auto"
    >
      <LogoBoxes3DLazy />
    </div>
  {/if}
  <div class="flex flex-col justify-between items-start">
    <div
      class="mb-4"
      class:type-title-sm={isInternal}
      class:type-title-md={!isInternal}
    >
      Are you an early-stage startup or academic?
    </div>
    <div class="mb-6" class:type-subtitle-md={!isInternal}>
      Startups and academic researchers can get up to $25k free compute credits
      on Modal. These credits can be used towards GPU compute and accessing
      in-demand GPU types.
    </div>
    <Cta
      primary={!isInternal}
      large={!isInternal}
      href="https://tally.so/r/wQ5GRl">Apply now</Cta
    >
  </div>
</div>
